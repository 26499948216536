<mat-toolbar>

  <button mat-icon-button (click)="menuClick()" aria-label="Menu toggle"
      matTooltip="{{ 'menu.menuToggle' | translate }}">
      <mat-icon>menu</mat-icon>
  </button>

  <div class="title" [routerLink]="'/'"> {{ 'app.title' | translate }} </div>

  <div class="remaining-space"></div>


  <app-help-button (click)="clickEvent.emit('help')" />

  <app-full-screen-button />
  
  <app-notifications-button (click)="clickEvent.emit('notifications')" />
  
  <app-preferences-button (click)="clickEvent.emit('preferences')" />

  <app-avatar-button />

</mat-toolbar>