import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { CustomHeader } from '../models/header.enum';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private readonly notificationService = inject(NotificationService);

  private requestsCount: number;
  constructor() {
    this.requestsCount = 0;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let skipSpinner = false;
    if (request.headers.has(CustomHeader.SkipLoadingHeader)) {
      request.headers.delete(CustomHeader.SkipLoadingHeader);
      skipSpinner = true;
    }
    if (!skipSpinner) {
      if (this.requestsCount <= 0) {
        this.notificationService.busy.set(true);
      }
      this.requestsCount++;
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!skipSpinner) {
          this.requestsCount--;
          if (this.requestsCount <= 0) {
            this.notificationService.busy.set(false);
          }
        }
      })
    );

  }
}
