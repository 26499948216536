import { Component, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-full-screen-button',
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './full-screen-button.component.html',
  styleUrl: './full-screen-button.component.scss'
})
export class FullScreenButtonComponent {
  public isFullscreen = signal(false);


  fullScreenClick(): void {
    if (this.isFullscreen()) {
      (document as Document).exitFullscreen()
    } else {
      (document.documentElement as HTMLElement).requestFullscreen()
    }
    this.isFullscreen.update(u => !u);
  }

}
