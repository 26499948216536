
import { Component, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FullScreenButtonComponent } from "./full-screen-button/full-screen-button.component";
import { AvatarButtonComponent } from "./avatar-button/avatar-button.component";
import { PreferencesButtonComponent } from "./preferences-button/preferences-button.component";
import { NotificationsButtonComponent } from "./notifications-button/notifications-button.component";
import { HelpButtonComponent } from "./help-button/help-button.component";
import { ToolbarButton } from '../../../models/toolbar-button.type';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCardModule,
    TranslateModule,
    FullScreenButtonComponent,
    AvatarButtonComponent,
    PreferencesButtonComponent,
    NotificationsButtonComponent,
    HelpButtonComponent,
    RouterModule
]
})
export class ToolbarComponent {
  public readonly menuToggleEvent = output();
  public readonly clickEvent = output<ToolbarButton>();


  menuClick(): void {
    this.menuToggleEvent.emit();
  }


}
