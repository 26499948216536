import { Component, computed, inject, Signal } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-avatar-button',
  imports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './avatar-button.component.html',
  styleUrl: './avatar-button.component.scss'
})
export class AvatarButtonComponent {
  public readonly displayName: Signal<string | null>;
  public readonly initials: Signal<string>;
  private readonly authService = inject(AuthService);
  
  constructor() {
    this.displayName = this.authService.displayName;
    this.initials = computed(() => this.displayName()?.split(' ').map(n => n.substring(0, 1)).join('') || '')
  }


  logout(): void {
    this.authService.signout();
  }

}
