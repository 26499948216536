<mat-sidenav-container autosize [hasBackdrop]="false">

  <mat-sidenav #leftSidenav [mode]="isMobile ? 'over' : 'side'" [opened]="!isMobile"
    class="left-sidenav mat-elevation-z3">
    <div class="flex-nav-container">

      <mat-nav-list>
        @for (menu of menus; track menu) {
        <a mat-list-item [routerLink]="menu.link" routerLinkActive="active-list-item" #nav="routerLinkActive"
          matTooltip="{{ menu.label | translate }}" matTooltipPosition="right"
          (click)="isMobile ? leftSidenav.close() : false">
          <mat-icon class="nav" [color]="nav.isActive?'accent' : ''">
            {{ menu.icon }}
          </mat-icon>
          <div>
            {{ menu.label | translate }}
          </div>
        </a>
        }
      </mat-nav-list>

      <mat-nav-list>
        <a mat-list-item [routerLink]="'settings'" routerLinkActive="active-list-item" #set="routerLinkActive"
          matTooltip="{{'menu.settings' | translate}}" matTooltipPosition="right"
          (click)="isMobile ? leftSidenav.close() : false">
          <mat-icon class="nav" [color]="set.isActive ? 'accent' : ''">
            settings
          </mat-icon>
          <div>
            {{ 'menu.settings' | translate }}
          </div>
        </a>
      </mat-nav-list>

    </div>
  </mat-sidenav>

  <mat-sidenav #rightSidenav position="end" mode="over" class="right-sidenav">
    <div class="right-close-placeholder">
      <button mat-icon-button (click)="rightSidenav.close()">
        <mat-icon color="accent">cancel</mat-icon>
      </button>
      <div>{{ 'menu.' + rightComponent | translate }}</div>
    </div>
    
    <mat-divider></mat-divider>

    @switch (rightComponent) {
    @case ('help') {
    <app-help></app-help>
    }
    @case ('notifications') {
    <app-notification></app-notification>
    }
    @case ('preferences') {
    <app-account></app-account>
    }
    }
  </mat-sidenav>

  <mat-sidenav-content>
    @if (busy()) {
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <div class="outlet-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>