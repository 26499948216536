import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';

@Component({
  selector: 'app-core',
  standalone: true,
  imports: [
    ToolbarComponent,
    SidenavComponent
  ],
  templateUrl: './core.component.html',
  styleUrl: './core.component.scss'
})
export class CoreComponent implements OnInit {
  private readonly notificationService = inject(NotificationService);
  private readonly translate = inject(TranslateService);
  private readonly authService = inject(AuthService);

  ngOnInit(): void {
    this.notificationService.setDefaultTheme();
    this.authService.isSupervisor().subscribe();
    this.configureLanguage();
    this.notificationService.checkForUpdates().subscribe(
      sw => console.log(sw.type)
    );
    this.notificationService.requestNotifications().subscribe();
  }


  private configureLanguage(): void {
    this.translate.setDefaultLang('pl-PL'); // fallback lang
    const lang = localStorage.getItem('language') || 'pl-PL'; // set polish as default 
    // this.translate.use(this.translate.getBrowserLang() || "en")
    this.translate.use(lang)
      .pipe(
        first()
      ).subscribe();
  }
}
