import { Component, inject, OnInit } from '@angular/core';
import { } from '@angular/router';
import { CoreComponent } from "./app-core/ui/core/core.component";
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  imports: [CoreComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private readonly matIconReg = inject(MatIconRegistry);
  ngOnInit(): void {
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');

  }

}
