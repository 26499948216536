import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Component, viewChild, inject, Signal } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { NavigationItems } from '../../../../app-core/models/navigation-item';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NotificationService } from '../../../../app-core/services/notification.service';
import { ToolbarButton } from '../../../models/toolbar-button.type';
import { MenuButton } from '../../../models/menu-button.model';
import { AccountComponent } from './account/account.component';
import { HelpComponent } from './help/help.component';
import { NotificationComponent } from './notification/notification.component';
@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    imports: [
        HelpComponent,
        NotificationComponent,
        AccountComponent,
        MatSidenavModule,
        MatListModule,
        RouterModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatButtonModule,
        TranslateModule,
        MatExpansionModule,
        CommonModule,
    ],
    providers: []
})
export class SidenavComponent {
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly notificationService = inject(NotificationService);
  private readonly router = inject(Router);

  readonly leftSidenav = viewChild.required<MatSidenav>('leftSidenav');
  readonly rightSidenav = viewChild.required<MatSidenav>('rightSidenav');
  public rightComponent: ToolbarButton | null = null;
  public navbarMini: boolean;
  public menus: MenuButton[] = NavigationItems;
  public busy: Signal<boolean>;
  public isMobile = false;

  constructor() {
    this.busy = this.notificationService.busy;
    this.navbarMini = JSON.parse(localStorage.getItem('menuMinimized') || true.toString());

    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(
      (result: BreakpointState) => this.isMobile = result.matches
    );
  }


  sidenavMinimize(event: MouseEvent): void {
    this.navbarMini = !this.navbarMini;
    event.stopPropagation();
    event.preventDefault();
    localStorage.setItem('menuMinimized', this.navbarMini.toString());
  }


  toggleRightMenu(component: ToolbarButton): void {
    if (this.isMobile) {
      this.router.navigate([component]);
      return;
    }

    const rightSidenav = this.rightSidenav();

    if (this.rightComponent === component && rightSidenav.opened) {
      rightSidenav.close();
      this.rightComponent = null;
      return;
    }

    this.rightComponent = component;
    rightSidenav.open();
  }
}
